import { NFT_API } from "constants/constants";
import axios from "./request";
import { useMemo } from "react";
import { getSushiPoints } from "utils/sushiHelpers";

const useNftApi = () => {
  const api = useMemo(() => {
    return {
      nft: {
        async findOne(tokenId: number) {
          const res = await axios.get(`${NFT_API}/token_uri/${tokenId}`);
          if (res?.data) {
            return res?.data;
          }

          throw Error("no data");
        },
        async stats() {
          const res = await axios.get(`${NFT_API}/stats`);
          if (res?.data) {
            return res?.data;
          }

          throw Error("no data");
        },
        async findMany(tokenIds: number[]) {
          const res = await axios.get(
            `${NFT_API}/token_uris/${tokenIds.join(",")}`
          );
          if (res?.data) {
            return res?.data?.data;
          }
          throw Error("no data");
        },
        async findGuild(walletAddress: string) {
          const res = await axios.get(
            `${NFT_API}/my_guild_nfts/${walletAddress}`
          );
          if (res?.data) {
            return res?.data;
          }

          throw Error("no data");
        },
        async joinGuild(walletAddress: string) {
          const res = await axios.get(`${NFT_API}/join_guild/${walletAddress}`);
          if (res?.data) {
            return res?.data;
          }

          throw Error("no data");
        },
        async findAll() {
          const nftJson = await fetch("./json/all-nfts.json").then((res) =>
            res.json()
          );
          const latestTokenId = nftJson.data.sort(
            (a, b) => b.tokenId - a.tokenId
          )?.[0]?.tokenId;

          const res = await axios.get(`${NFT_API}/metadata/${latestTokenId}`);
          if (res?.data) {
            const latestMetadata = nftJson.data;
            const {
              metadata: fetchedMetadata,
              burntTokenIds,
              rankMapping,
              stakings,
            } = res?.data?.data;
            const allMetadata = [...latestMetadata, ...fetchedMetadata];
            const allData = allMetadata.map((item) => {
              return {
                index: item.tokenId,
                metadata: item,
                isBurnt: burntTokenIds.includes(item.tokenId),
                rank: rankMapping[item.tokenId]
                  ? rankMapping[item.tokenId]
                  : null,
                staking: stakings.filter((x) => x.tokenId === item.tokenId),
                totalOma: stakings
                  .filter((x) => x.tokenId === item.tokenId)
                  .reduce(
                    (b, a) =>
                      getSushiPoints({
                        id: a.id,
                        tokenId: a.tokenId,
                        rank: rankMapping[item.tokenId] ?? 15000,
                        startTime: a.startTime,
                        endTime: a.endTime,
                      })?.accruedPoints + b,
                    0
                  ),
              };
            });
            return allData;
          }
          throw Error("no data");
        },
      },
    };
  }, []);

  return { api };
};

export default useNftApi;
